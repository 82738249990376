<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      categoryData: [],
      categoryOptionList: [],
      categoryTotalPage: null,
      categoryCurrentPage: null,
      trashData: [],
      totalRows: 1,
      current_page: 1,
      currentPage: null,
      per_page: null,
      perPage: 15,
      trash_total_rows: 1,
      trash_per_page: 15,
      trash_current_page: 1,
      pageOptions: [5, 10, 15, 25, 50],
      filter: null,
      filterOn: [],
      search: '',
      sortBy: "orderid",
      sortDesc: false,
      allFields: [
        { key: "image", label: "Image" },
        { key: "banner", label: "Banner" },
        { key: "name", sortable: true, label: "Name" },
        { key: "parent_category", label: "Parent", thStyle: { width: "20%" }},
        { key: "title", label: "Title" },
        { key: "slug", label: "Slug" },
        { key: "action", label: "Action" },

      ],
    };
  },
  computed: {
    rows() {
      return this.categoryData.length;
    },
  },
  mounted() {
    this.getCategoryData(1);
    this.getCategory()
    this.totalRows = this.categoryData.length;
  },
  methods: {
    goToCreateCategories() {
      this.$router.push({ name: "Add Promotional Category" });
    },
    reachedEndOfCategory(reached) {
      if (reached && this.categoryCurrentPage < this.categoryTotalPage) {
        this.getCategory();
      }
    },
    getCategory() {
      this.$store.getters.client.get(`/midocean/promotional-category/?page=${this.categoryCurrentPage + 1}`).then((response) => {
        let resultData = response.data;
        this.categoryOptionList = this.categoryOptionList.concat(resultData.results);
        this.categoryTotalPage = resultData.total_pages;
        this.categoryCurrentPage = resultData.current_page;
      });
    },
    getCategoryData(value) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client.get(`/midocean/promotional-category/?page=${value}`).then((response) => {
        this.categoryData = response.data.results;
        this.totalRows = response.data.total_count;
        this.per_page = response.data.per_page;
        loader.hide();
      });
    },
    editCategory(categories) {
      this.$router.push({ path: `/update-promotional-category/${categories.item.id}` });
    },
    updateParent(item) {
      let data = []
      item.parent_category.forEach((element) => {
        data.push(element.id)
      })
      this.$store.getters.client.patch(
        `/midocean/promotional-category/${item.id}/`,
        { parent_category: String(data[0])}
      );
    },
    confirm(categories) {
      Swal.fire({
        title: "Move to trash",
        text: "You can delete it from trash",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        this.$store.getters.client.delete(`/midocean/promotional-category/${categories.item.id}/`)
        this.categoryData = this.categoryData.filter((x) => {
          return x.id != categories.item.id
        })
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    transferToCategory() {
      console.log(this.categoryData)
      // this.$store.getters.client
      //       .post("/categories/", formData)
      //       .then(() => {
      //         this.$swal({
      //           position: "top-end",
      //           icon: "success",
      //           title: "Category created successfully",
      //           showConfirmButton: false,
      //           timer: 1500,
      //         });
      //         this.$router.push({ name: "Categories" });
      //       });
    }
  },
};
</script>
<template>
  <div>
    <div class="add-button">
      <a href="javascript:void(0);" class="btn btn-primary" @click="goToCreateCategories">
        + Add New Category
      </a>
      <a href="javascript:void(0);" class="btn btn-primary" @click="transferToCategory">
        Transfer Category
      </a>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body pt-0">
            <div class="table-responsive">
              <b-table :items="categoryData" :fields="allFields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template v-slot:cell(image)="row">
                  <div class="d-flex align-items-center">
                    <img :src="row.item.image" alt="image" height="60" class="rounded">
                  </div>
                </template>
                <template v-slot:cell(banner)="row">
                  <div class="d-flex align-items-center">
                    <img :src="row.item.banner" alt="banner" height="60" class="rounded">
                  </div>
                </template>
                <template v-slot:cell(parent_category)="row">
                    <div class="d-flex align-items-center">
                      <multiselect name="category" v-model="row.item.parent_category" :options="categoryOptionList"
                        :multiple="true" label="name" track-by="id" :hide-selected="true" :searchable="true"
                        @input="updateParent(row.item)">
                        <template slot="afterList">
                          <div v-observe-visibility="reachedEndOfCategory" />
                        </template>
                      </multiselect>
                    </div>
                  </template>
                <template v-slot:cell(action)="row">
                  <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover data-toggle="tooltip"
                    title="Edit" @click="editCategory(row)">
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                    <i @click="confirm(row)" class="mdi mdi-trash-can font-size-18"></i>
                  </a>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="current_page" :total-rows="totalRows" :per-page="per_page"
                      @change="getCategoryData"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.add-button {
  margin-bottom: 27px;
  margin-left: 14px;
}
</style>
