<template>
  <div class="col-lg-12">
    <div class="card px-3 pb-3">
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select v-model="per_page" size="sm" :options="[5, 10, 15]"></b-form-select>&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input @keypress.enter="getProductData(1)" type="text" v-model="search" class="form-control form-control-sm ml-2"></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table class="table-centered product-table" :items="products" :fields="allFields" responsive="sm">
          <template v-slot:cell(digital_assets)="row">
            <img class="rounded-4" :src="row.item.variants[0].digital_assets[0].url" alt="" width="80" />
          </template>
          <!-- <template v-slot:cell(id)="row">
            <div>{{ row.item.master_id }}</div>
            <div>{{ row.item.master_code }}</div>
          </template> -->
          <template v-slot:cell(categories)="row">
            <ul class="m-0 p-0">
              <li>{{ row.item.variants[0].category_level1 }}</li>
              <li>{{ row.item.variants[0].category_level2 }}</li>
              <li>{{ row.item.variants[0].category_level3 }}</li>
            </ul>
          </template>
          <template v-slot:cell(slug)="row">
            <b-form-input v-model="row.item.slug" @blur="updateSlug(row.item)"></b-form-input>
          </template>
          <template v-slot:cell(category)="row">
            <div class="d-flex align-items-center">
              <multiselect name="category" v-model="row.item.category" :options="categoryOptionList" label="name" track-by="id" :hide-selected="true" :searchable="true" :multiple="true" 
                @input="updateCategory(row.item)">
                <template slot="afterList">
                  <div v-observe-visibility="reachedEndOfCategory" />
                </template>
              </multiselect>
            </div>
          </template> 
          <template v-slot:cell(action)="row">
            <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
              <template v-slot:button-content>
                <i class="mdi mdi-dots-vertical"></i>
              </template>
              <b-dropdown-item><router-link class="d-block"
                  :to="`/edit-product/` + row.item.master_id">Edit</router-link></b-dropdown-item>
              <b-dropdown-item><router-link class="d-block" :to="`/product/${row.item.master_id}/variant-price`">Add
                  Price
                  (Mannual)</router-link></b-dropdown-item>
              <b-dropdown-item><router-link class="d-block" :to="`/product/${row.item.master_id}/update-price`">Update
                  Price</router-link></b-dropdown-item>
              <b-dropdown-item><router-link class="d-block" :to="`/product/${row.item.master_id}/manage-supplier`">Add
                  Supplier</router-link></b-dropdown-item>
              <b-dropdown-item><router-link class="d-block"
                  :to="`/sorting/${row.item.master_id}`">Sorting</router-link></b-dropdown-item>
              <b-dropdown-item variant="danger">Delete</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="current_page" :total-rows="totalRows" :per-page="per_page"
                @change="getProductData">
              </b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      categoryOptionList: [],
      categoryCurrentPage: 0,
      categoryTotalPage: null,
      search: '',
      products: null,
      totalRows: 1,
      current_page: 1,
      per_page: 15,
      allFields: [
        { key: "digital_assets", sortable: true, label: "Image" },
        { key: "short_description", sortable: true, label: "Name" },
        // { key: "id", label: "Unique ID" },
        { key: "categories", label: "Categories" },
        { key: "category", label: "Category" },
        {
          key: "slug",
          label: "Slug",
          thStyle: { width: "25%" },
        },
        { key: "Action" },
      ],
    };
  },
  mounted() {
    this.getCategory();
    this.getProductData(1);
  },
  methods: {
    getCategory() {
      this.$store.getters.client.get(`/categories/all/?page=${this.categoryCurrentPage + 1}`).then((response) => {
        let resultData = response.data;
        this.categoryOptionList = this.categoryOptionList.concat(resultData.results);
        this.categoryTotalPage = resultData.total_pages;
        this.categoryCurrentPage = resultData.current_page;
      });
    },
    reachedEndOfCategory(reached) {
      if (reached && this.categoryCurrentPage < this.categoryTotalPage) {
        this.getCategory();
      }
    },
    async getProductData(value) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      if(this.search !== '' || this.search !== null){
        this.$store.getters.client
          .get(`/midocean/promotional-products/?search=${this.search}&page=${value || 1}`)
          .then((response) => {
            this.products = response.data.results;
            this.totalRows = response.data.total_count;
            this.per_page = response.data.per_page;
          });
      }else{
        await this.$store.getters.client
          .get(`/midocean/promotional-products/?page=${value || 1}`)
          .then((response) => {
            this.products = response.data.results;
            this.totalRows = response.data.total_count;
            this.per_page = response.data.per_page;
          });
      }
      loader.hide();
    },

    updateSlug(item) {
      this.$store.getters.client
        .patch(`/midocean/promotional-products/${item.master_id}/`, {slug: item.slug})
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          alert(error)
        });
    },
    updateCategory(item) {
      const category = item.category.map((item) => item.id)
      this.$store.getters.client
        .patch(`/midocean/promotional-products/${item.master_id}/`, {category: category})
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          alert(error)
        });
    },
    myRowClickHandler(event) {
      this.$router.push({ name: "Product Details", params: { id: event.master_id } });
    },
  },
};
</script>